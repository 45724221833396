/*eslint-disable*/
import React from "react";
import {graphql, StaticQuery} from "gatsby";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

function HeaderLinks(data) {
  let classes = useStyles();
  return (
    <>
    { data.data.allMarkdownRemark.edges !== undefined && data.data.allMarkdownRemark.edges.length > 0 &&
    <List className={classes.list}>
        { data.data.allMarkdownRemark.edges[0].node.frontmatter.mainmenu.menuItems.map((menuItem, i) => (
        <ListItem key={i} className={classes.listItem}>
         <Button
           href={menuItem.url}
           color="transparent"
           target="_blank"
           className={classes.navLink}
         >
           {menuItem.title}
         </Button>
        </ListItem>
  ))}
  
    </List>
    }
    </>
  );
}

export default () => (
  <StaticQuery
      query={graphql`
        query MainMenuQuery {
          allMarkdownRemark (filter: {frontmatter: {templateKey: {eq: "menu-items"}}}) {
            edges{
              node{
                frontmatter{
                  templateKey
                  mainmenu {
                    menuItems{
                      title
                      url
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => <HeaderLinks data={data} />}
  />
);