/*eslint-disable*/
import React from "react";
import {graphql, StaticQuery} from "gatsby";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

function Footer(data) {
  const classes = useStyles();
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: false
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: false
  });
  return (
    <footer className={footerClasses}>
    { data.data.allMarkdownRemark.edges !== undefined && data.data.allMarkdownRemark.edges.length > 0 &&
      <div className={classes.container}>

        <div className={classes.left}>
          <List className={classes.list}>
            { data.data.allMarkdownRemark.edges[0].node.frontmatter.servicemenu.menuItems.map((menuItem, i) => {
                return (
                  <ListItem key={i} className={classes.inlineBlock}>
                    <a
                      href={menuItem.url}
                      className={classes.block}
                      target="_blank"
                      >
                      {menuItem.title}
                    </a>
                  </ListItem>
              )
            })}
          </List>
        </div>
        <div className={classes.right}>
          {data.data.allMarkdownRemark.edges[0].node.frontmatter.servicemenu.serviceText}
        </div>
      </div>
    }
    </footer>
  );
}

export default () => (
  <StaticQuery
      query={graphql`
        query ServiceMenuQuery {
          allMarkdownRemark (filter: {frontmatter: {templateKey: {eq: "menu-items"}}}) {
            edges{
              node{
                frontmatter{
                  templateKey
                  servicemenu {
                    serviceText
                    menuItems{
                      title
                      url
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => <Footer data={data} />}
  />
);
